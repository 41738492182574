import React, { useState, useRef } from 'react';
import commands, { websiteCommands, socialsCommands } from './commands';
import './Terminal.css'

function Terminal() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([{ role: 'init', content: "Hi! I'm Nguyen Banh! How can I help you? Type 'help' for a list of commands." }]);
  const inputRef = useRef();
  const [commandContext, setCommandContext] = useState(null);

  async function sendToOpenAI(message) {
    try{
    const response = await {async fetch(request, env, ctx) {
      return await Response(` ${env.WORKER_B.fetch('https://openai-function-calling-workers.dt44p4frgn.workers.dev/?message='+message)}`);},};
    console.log(response);
    const data = await response.json();
    console.log('Data:', data);

    // Assumption: The API returns a JSON object with a 'content' property
    return data.content;
  } catch (error) {
    console.error('Error fetching from OpenAI API:', error);
    return 'Error fetching OpenAI response';
  }
}
  const handleInput = (e) => {
    setInput(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${Math.min(e.target.scrollHeight, window.innerHeight * 0.4)}px`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInput = input.trim().toLowerCase();
    let newOutput = [...output, { role: 'user', content: userInput }];

    if (commands.hasOwnProperty(userInput)) {
      const commandResult = commands[userInput]();

      if (commandResult.type === 'text') {
        newOutput.push({ role: 'assistant', content: commandResult.value });
      } else if (commandResult.type === 'link') {
        window.open(commandResult.value, '_blank', 'noopener,noreferrer');
      } else if (commandResult.type === 'clear') {
        window.location.reload();
      }

      if (commandResult.context) {
        setCommandContext(commandResult.context);
      } else {
        setCommandContext(null);
      }

    } else if (commandContext === 'websites' && websiteCommands.hasOwnProperty(userInput)) {
      const commandResult = websiteCommands[userInput]();
      if (commandResult.type === 'link') {
        window.open(commandResult.value, '_blank', 'noopener,noreferrer');
      }
      setCommandContext(null);
    } else if (commandContext === 'socials' && socialsCommands.hasOwnProperty(userInput)) {
      const commandResult = socialsCommands[userInput]();
      if (commandResult.type === 'link') {
        window.open(commandResult.value, '_blank', 'noopener,noreferrer');
      }
      setCommandContext(null);
    } else {
      try {
        const aiResponse = await sendToOpenAI(userInput);
        newOutput.push({ role: 'assistant', content: aiResponse });
      } catch (error) {
        console.error(error);
      }
    }

    if ((commandContext === 'websites' && !websiteCommands.hasOwnProperty(userInput)) ||
      (commandContext === 'socials' && !socialsCommands.hasOwnProperty(userInput))) {
      setCommandContext(null);
    }

    setOutput(newOutput);
    setInput('');
  };

  return (
    <div onClick={() => inputRef.current.focus()} className="terminal">
      <div className="message-container">
        {output.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.content}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        User:&nbsp;
        <textarea
          className="terminal-input"
          rows="1"
          ref={inputRef}
          type="text"
          value={input}
          onChange={handleInput}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              if (!e.shiftKey) {
                e.preventDefault();
                await handleSubmit(e);
              }
            }
          }}
        />
      </form>
    </div>
  );
}

export default Terminal;