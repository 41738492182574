const commands ={
	/* define each command as an object, with type either text or link, value being what is returned in the output, and context sets the state for further nested commands */
	help: () => ({ /* if user types help, display list of commands */
		type: "text", /* type is either text or link. Add new types in terminal.jsx file */
		value: /* value is what is returned in the output */
        "Available commands: \n" + 
			"'websites' - view a list of Nguyen's websites \n" +
			"'hi' - say hello to Nguyen \n" +
			"'clear' - clear the terminal \n" +
			"'socials' - view a list of Nguyen's social media accounts \n" + 
			"'about' - learn more about Nguyen \n" +
			"'contact' - send Nguyen an email\n"
		/* !todo	"'more commands", */
		}),
	about: () => ({ type: "text", value: "Hi! My name is Nguyen Banh, I'm 33 years old IT sys admin, web developer, and a Computer Engineering!. \b\n" + 
		"I'm intersted in Cybersecurity, and I'm currently working on a lot of new projects and IT cyber certs, while mastering languages like javascript, python! \b\n" + 
		"When I'm not working, studying or hacking, I love listening to music, or playing video games. \b\n",
	}),
	hi: () => ({ type: "text", value: "Hello! :)",}),

	contact: () => ({ type: "link", value: "mailto:nguyen@ntbanh.com",}),
	websites: () => ({
		type: "text",
		value: "What website would you like to visit: \b\n" +
		"'portfolio' - Nguyen's personal portfolio site \b\n" +
		"'docs' - Nguyen's technical documentaiton \b\n" /* !todo 
		"website3" */,
		context: "websites",
	}),
	socials: () => ({
		type: "text",
		value: "What social media account would you like to visit: \b\n" +
		"'github' - Nguyen's github account \b\n" +
		"'linkedin' - Nguyen's linkedin account \b\n" ,
		//"'instagram' - Ellie's instagram account \b\n" +
		//"'facebook' - Ellie's facebook account \b\n" ,
		context: "socials",

	}),
	clear: () => ({ type: "clear",}), /* clear performs a window reload, and has no output */
}

const websiteCommands = { /* each nested command is defined in the same way as the parent command */
    portfolio: () => ({ type: "link", value: "https://ntbanh.com",}),
    docs: () => ({ type: "link", value: "https://ntbanh.com",}),
}	

const socialsCommands = { 
	github: () => ({ type: "link", value: "https://github.com/nguyentbanh",}),
	linkedin: () => ({ type: "link", value: "https://www.linkedin.com/in/nguyen-banh-5042a0115/",}),
//	instagram: () => ({ type: "link", value: "https://www.instagram.com/biohacker_ellie/",}),
//	facebook: () => ({ type: "link", value: "https://www.facebook.com/elliana.kerns/",}),
}

export default  commands;
export { websiteCommands, socialsCommands};

